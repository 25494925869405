<template>
  <div id="app" class="page-wrapper">
    <LandingPage />
  </div>
</template>

<script>
import LandingPage from './pages/Landing'
export default {
  name: "app",
  components: {
    LandingPage
  },
};
</script>