<template>
  <div class="row">
    <!-- Program Block -->
      <div class="program-block col-lg-4 col-md-6 col-sm-12 wow fadeInUp" v-for="item in programsData" :key="item.key" >
        <div class="inner-box">
          <div class="image-box">
            <figure class="image">
              <a href="numbers-matching.html">
                <img :src="imagUrl+item.picture" alt="" v-if="item.picture" style="height:315px;widht:370px;"/>
                <img src="images/thumbnail_program.jpg" alt="" v-else/>
                </a>
            </figure>
          </div>
          <div class="lower-content">
            <h4><a href="#">{{item.name}}</a></h4>
            <div class="text" style="font-size: 14px;">
              {{item.tentang}}
            </div>
            <ul class="program-info">
              <a :href="item.weburl" class="btn btn-success">Kunjungi Website</a>
            </ul>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
export default {
  props: {
      idSection: String,
      programsData: Array
    },
  data(){
    return{
      imagUrl: "https://sdit.balikpapanislamicschool.sch.id/po-content/uploads/",

    }
  }
}
</script>

<style>

</style>