import api from '../services/apiServices';
const getLanding = () => {
  return api.get('/landing/'+1);
};

const dataWeb = () => {
  return api.get('/webdata');
};

const sendContact = (body) => {
  return api.post('/send/contact', body)
};
export default {
  getLanding,
  dataWeb,
  sendContact
}
