<template>
  <section class="about-section-two" :id="idSection">
      <div class="auto-container">
        <div class="parallax-scene parallax-scene-1 anim-icons">
          <span
            data-depth="0.20"
            class="parallax-layer icon icon-rainbow-3"
          ></span>
          <span
            data-depth="0.40"
            class="parallax-layer icon icon-star-2"
          ></span>
          <span
            data-depth="0.30"
            class="parallax-layer icon icon-star-5"
          ></span>
          <span
            data-depth="0.50"
            class="parallax-layer icon icon-plane-2"
          ></span>
        </div>

        <div class="sec-title text-center">
          <span class="title">Introduction</span>
          <h2>Selamat Datang di <br />Balikpapan Islamic School</h2>
        </div>

        <div class="row">
          <!-- Content Column -->
          <div class="content-column col-lg-6 col-md-12 col-sm-12 order-2">
            <div class="inner-column">
              <div class="text">
                {{abouts.tentang}}
              </div>
            </div>
          </div>

          <div class="image-column col-lg-6 col-md-12 col-sm-12">
            <div class="inner-column">
              <div class="image-box">
                <figure class="image wow fadeInLeft">
                  <img src="@/assets/picture1.png" alt="" />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
</template>

<script>
export default {
  props: {
      idSection: String,
      abouts: Object
    }
}
</script>

<style>

</style>