<template>
  <section class="events-section" :id="idSection">
      <div class="auto-container">
        <div class="sec-title black text-center">
          <span class="title">Upcoming Events</span>
          <h2>Jadwal Kegiatan</h2>
        </div>

        <div class="row">
          <!-- Event Block -->
          <div class="event-block col-xl-6 col-md-12 col-sm-12 wow fadeInUp" v-for="item in eventsData" :key="item.key">
            <div class="inner-box" >
                  <div class="image-box" style="height:270px ;width: 215px;">
                      <figure class="image" style=" height: 270px;"> 
                          <img :src="imagUrl+item.picture" alt="" style="height: 270px ;" v-if="item.picture">
                          <img src="thumbnail_program.jpg" alt="" v-else/>
                        </figure>
                  </div>
                  <div class="content-box">
                      <div class="date" style="font-size:12px;">{{item.date}}</div>
                      <h4><a href="#" style="font-size:22px;">{{item.title}}</a></h4>
                  </div>
              </div>
          </div>
        </div>
        <!-- <div class="btn-box text-center ">
          <a href="events.html" class="theme-btn btn-style-one dark-btn"
            >View All Programs</a
          >
        </div> -->
      </div>
    </section>
</template>

<script>
export default {
  props: {
    idSection: String,
    eventsData: Array
  },
  data(){
    return{
      imagUrl: "https://sdit.balikpapanislamicschool.sch.id/po-content/uploads/",
    }
  }
}
</script>

<style scoped>
.dark-btn{
  background-color: #282F39;
}

@media only screen and (max-width: 479px) {
 .image-box{
   display: none;
 }
}
</style>