<template>
<carousel class="banner-carousel" :paginationEnabled="false"  :perPage="1" :autoplay="true" :autoplayTimeout="300" :navigationEnabled="true">
  <slide v-for="item in carousels" :key="item.key">
    <img :src="imagUrl+item.picture" :alt="item.title">
  </slide>
</carousel>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';
export default {
  props: {
    carousels: Array,
  },
  components: {
      Carousel,
        Slide
  },
  data() {
    return {
      carousel: [],
      imagUrl: "https://sdit.balikpapanislamicschool.sch.id/po-content/uploads/",
    };
  },
  mounted(){
    
  }
};
</script>

<style scoped>
.banner-carousel{
    position: relative;
    height: 110vh;
    /* background-color: black; */
}
.banner-carousel img {
    width: 100%;
    height: 100vh;
    object-fit: fill;
}
@media only screen and (max-width: 479px) {
  .banner-carousel{
    display: none;
  }
}
</style>