<template>
  <div>
    <header class="main-header header-style-two">
      <!-- Header Upper -->
      <div class="header-lower">
        <div class="auto-container clearfix">
          <!--Info-->
          <div class="logo-outer">
            <div class="logo">
              <a href="#"
                ><img src="images/bis/header_logo.png" alt="" title="" class="logo_"
              /></a>
            </div>
          </div>

          <!--Nav Box-->
          <div class="nav-outer clearfix">
            <!-- Main Menu -->
            <nav class="main-menu navbar-expand-md navbar-light">
              <div class="navbar-header">
                <!-- Togg le Button -->
                <button
                  class="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span class="icon flaticon-menu-button"></span>
                </button>
              </div>

              <div
                class="collapse navbar-collapse clearfix"
                id="navbarSupportedContent"
              >
                <ul class="navigation clearfix">
                 <li><a href="#">Beranda</a></li>
                  <li><a href="#about">Tentang</a></li>
                  <li class="dropdown">
                    <a href="#program">Program</a>
                    <ul>
                      <li v-for="item in program" :key="item.key">
                        <a :href="item.weburl">{{item.name}}  </a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="#news">Our News</a>
                  </li>
                  <li><a href="#contact">Contact</a></li>
                  <li>
                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSdp4Z1okFC828a66gSjHOkEVwmk2Z_hrGkVcyegttgj-La57A/viewform?fbzx=-5658271171187797998" target="_blank">PPDB</a>
                  </li>
                </ul>
              </div>
            </nav>
            <!-- Main Menu End-->

            <!-- Main Menu End-->
            <div class="outer-box clearfix">
              <ul class="social-icon-colored">
                <li>
                  <a :href="facebookurl"><span class="fab fa-facebook-f"></span></a>
                </li>
                <li>
                  <a :href="instagram"><span class="fab fa-instagram"></span></a>
                </li>
                <li>
                  <a :href="youtubeurl"><span class="fab fa-youtube"></span></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <!--End Header Upper-->

      <!-- Sticky Header  -->
      <div class="sticky-header">
        <div class="auto-container clearfix">
          <!--Logo-->
          <div class="logo pull-left">
            <a href="#" title=""
              ><img src="images/bis/header_logo.png" alt="" title=""
            /></a>
          </div>
          <!--Right Col-->
          <div class="pull-right">
            <!-- Main Menu -->
            <nav class="main-menu">
              <div class="navbar-collapse show collapse clearfix">
                 <ul class="navigation clearfix">
                 <li><a href="#">Beranda</a></li>
                  <li><a href="#about">Tentang</a></li>
                  <li class="dropdown">
                    <a href="#program">Program</a>
                    <ul>
                      <li v-for="item in program" :key="item.key">
                        <a :href="item.weburl">{{item.name}} </a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="#news">Our News</a>
                  </li>
                  <li><a href="#contact">Contact</a></li>
                  <li>
                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSdp4Z1okFC828a66gSjHOkEVwmk2Z_hrGkVcyegttgj-La57A/viewform?fbzx=-5658271171187797998" target="_blank">PPDB</a>
                  </li>
                </ul>
              </div>
            </nav>
            <!-- Main Menu End-->
          </div>
        </div>
      </div>
      <!-- End Sticky Menu -->
    </header>
  </div>
</template>

<script>
export default {
  props:{
    facebookurl: String,
    instagram: String,
    youtubeurl: String,
    program: Array
  }
}
</script>

<style>

</style>