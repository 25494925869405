var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('header',{staticClass:"main-header header-style-two"},[_c('div',{staticClass:"header-lower"},[_c('div',{staticClass:"auto-container clearfix"},[_vm._m(0),_c('div',{staticClass:"nav-outer clearfix"},[_c('nav',{staticClass:"main-menu navbar-expand-md navbar-light"},[_vm._m(1),_c('div',{staticClass:"collapse navbar-collapse clearfix",attrs:{"id":"navbarSupportedContent"}},[_c('ul',{staticClass:"navigation clearfix"},[_vm._m(2),_vm._m(3),_c('li',{staticClass:"dropdown"},[_c('a',{attrs:{"href":"#program"}},[_vm._v("Program")]),_c('ul',_vm._l((_vm.program),function(item){return _c('li',{key:item.key},[_c('a',{attrs:{"href":item.weburl}},[_vm._v(_vm._s(item.name)+" ")])])}),0)]),_vm._m(4),_vm._m(5),_vm._m(6)])])]),_c('div',{staticClass:"outer-box clearfix"},[_c('ul',{staticClass:"social-icon-colored"},[_c('li',[_c('a',{attrs:{"href":_vm.facebookurl}},[_c('span',{staticClass:"fab fa-facebook-f"})])]),_c('li',[_c('a',{attrs:{"href":_vm.instagram}},[_c('span',{staticClass:"fab fa-instagram"})])]),_c('li',[_c('a',{attrs:{"href":_vm.youtubeurl}},[_c('span',{staticClass:"fab fa-youtube"})])])])])])])]),_c('div',{staticClass:"sticky-header"},[_c('div',{staticClass:"auto-container clearfix"},[_vm._m(7),_c('div',{staticClass:"pull-right"},[_c('nav',{staticClass:"main-menu"},[_c('div',{staticClass:"navbar-collapse show collapse clearfix"},[_c('ul',{staticClass:"navigation clearfix"},[_vm._m(8),_vm._m(9),_c('li',{staticClass:"dropdown"},[_c('a',{attrs:{"href":"#program"}},[_vm._v("Program")]),_c('ul',_vm._l((_vm.program),function(item){return _c('li',{key:item.key},[_c('a',{attrs:{"href":item.weburl}},[_vm._v(_vm._s(item.name)+" ")])])}),0)]),_vm._m(10),_vm._m(11),_vm._m(12)])])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"logo-outer"},[_c('div',{staticClass:"logo"},[_c('a',{attrs:{"href":"#"}},[_c('img',{staticClass:"logo_",attrs:{"src":"images/bis/header_logo.png","alt":"","title":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"navbar-header"},[_c('button',{staticClass:"navbar-toggler",attrs:{"type":"button","data-toggle":"collapse","data-target":"#navbarSupportedContent","aria-controls":"navbarSupportedContent","aria-expanded":"false","aria-label":"Toggle navigation"}},[_c('span',{staticClass:"icon flaticon-menu-button"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"#"}},[_vm._v("Beranda")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"#about"}},[_vm._v("Tentang")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"#news"}},[_vm._v("Our News")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"#contact"}},[_vm._v("Contact")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"https://docs.google.com/forms/d/e/1FAIpQLSdp4Z1okFC828a66gSjHOkEVwmk2Z_hrGkVcyegttgj-La57A/viewform?fbzx=-5658271171187797998","target":"_blank"}},[_vm._v("PPDB")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"logo pull-left"},[_c('a',{attrs:{"href":"#","title":""}},[_c('img',{attrs:{"src":"images/bis/header_logo.png","alt":"","title":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"#"}},[_vm._v("Beranda")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"#about"}},[_vm._v("Tentang")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"#news"}},[_vm._v("Our News")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"#contact"}},[_vm._v("Contact")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"https://docs.google.com/forms/d/e/1FAIpQLSdp4Z1okFC828a66gSjHOkEVwmk2Z_hrGkVcyegttgj-La57A/viewform?fbzx=-5658271171187797998","target":"_blank"}},[_vm._v("PPDB")])])
}]

export { render, staticRenderFns }