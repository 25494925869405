<template>
  <footer class="main-footer" :id="idSection">
      <div class="anim-icons">
        <span
          class="icon icon-sparrow wow zoomIn"
          data-wow-delay="400ms"
        ></span>
        <span
          class="icon icon-rainbow-2 wow zoomIn"
          data-wow-delay="800ms"
        ></span>
        <span class="icon icon-star-3"></span>
        <span class="icon icon-star-3 two"></span>
        <span class="icon icon-sun"></span>
        <span class="icon icon-plane wow zoomIn" data-wow-delay="1200ms"></span>
      </div>

      <!--Scroll to top-->
      <div class="scroll-to-top scroll-to-target" data-target="html">
        <span class="icon icon-arrow-up"></span>
      </div>

      <!--footer upper-->
      <div class="footer-upper">
        <div class="auto-container">
          <div class="row clearfix">
            <!--Big Column-->
            <div class="big-column col-xl-5 col-lg-12 col-md-12 col-sm-12">
              <div class="row clearfix">
                <!--Footer Column-->
                <div class="footer-column col-lg-6 col-md-6 col-sm-12">
                  <div class="footer-widget logo-widget">
                    <div class="logo">
                      <a href="#"
                        ><img src="images/bis/header_logo.png" alt=""
                      /></a>
                    </div>
                    <!-- <div class="text">
                      Etiam rhoncus sit amet adip scing sed ipsum. Lorem ipsum
                      dolor sit amet adipiscing sem neque.
                    </div> -->
                  </div>
                </div>

                <!--Footer Column-->
                <div class="footer-column col-lg-5 col-md-6 col-sm-12">
                  <div class="footer-widget contact-widget">
                    <h4 class="widget-title">Kontak</h4>
                    <div class="widget-content">
                      <ul class="contact-info">
                        <li>
                          <a :href="'tel:'+tlp"
                            ><span class="fa fa-phone-square"></span>{{tlp}}</a
                          >
                        </li>
                        <li>
                          <a :href="'mailto:'+email"
                            ><span class="fa fa-envelope"></span
                            >{{email}}</a
                          >
                        </li>
                        <li>
                          <span class="fa fa-map"></span> {{address}}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!--Big Column-->
            <div class="big-column col-xl-7 col-lg-12 col-md-12 col-sm-12">
              <div class="row clearfix">
                <!--Footer Column-->
                <div class="footer-column col-xl-3 col-lg-3 col-md-6 col-sm-4">
                  <div class="footer-widget links-widget">
                    <h4 class="widget-title">Link Terkait</h4>
                    <div class="widget-content">
                      <ul class="list">
                        <li><a href="https://sdit.balikpapanislamicschool.sch.id/" target="_blank" >SDIT Balikpapan Islamic School</a></li>
                        <li><a href="https://smpit.balikpapanislamicschool.sch.id/" target="_blank">SMPIT Modern Balikpapan Islamic School</a></li>
                      </ul>
                    </div>
                  </div>
                </div>

                <!--Footer Column-->
                <!-- <div class="footer-column col-xl-4 col-lg-3 col-md-6 col-sm-12">
                  <div class="footer-widget activites-widget">
                    <h4 class="widget-title">Activities</h4>
                    <div class="widget-content">
                      <ul class="list">
                        <li><a href="#">Table/Floor Toys</a></li>
                        <li><a href="#">Outdoor Games</a></li>
                        <li><a href="#">Sand Play</a></li>
                        <li><a href="#">Play Dough</a></li>
                        <li><a href="#">Building Blocks</a></li>
                        <li><a href="#">Water Play</a></li>
                      </ul>
                    </div>
                  </div>
                </div> -->

                <!--Footer Column-->
                <!-- <div
                  class="footer-column col-xl-5 col-lg-6 col-md-12 col-sm-12"
                >
                  <div class="footer-widget newsletter-widget">
                    <h4 class="widget-title">Newsletter</h4>
                    <div class="newsletter-form">
                      <form method="post" action="contact.html">
                        <div class="form-group">
                          <input
                            type="email"
                            name="email"
                            value=""
                            placeholder="Enter Your Email"
                            required=""
                          />
                          <button type="submit" class="theme-btn btn-style-one">
                            Subscribe
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--Footer Bottom-->
      <div class="footer-bottom">
        <div class="auto-container">
          <ul class="social-icon-colored">
            <li>
              <a :href="facebookurl"><span class="fab fa-facebook-f"></span></a>
            </li>
            <li>
              <a :href="instagramurl"><span class="fab fa-instagram"></span></a>
            </li>
            <li>
              <a :href="youtubeurl"><span class="fab fa-youtube"></span></a>
            </li>
          </ul>
          <div class="copyright">
            Copyrights &copy; {{currentYear}} - Balikpapan Islamic School
          </div>
        </div>
      </div>
    </footer>
</template>

<script>
export default {
  props: {
    idSection: String,
    facebookurl: String,
    instagramurl: String,
    youtubeurl: String,
    tlp: String,
    email:String,
    address: String
  },
  computed: {
    currentYear: () => {
      return new Date().getFullYear();
    }
  }
}
</script>

<style>

</style>