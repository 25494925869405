<template>
  <div>
    <!-- Preloader -->
    <div class="preloader"></div>
    <Header  
      :facebookurl="landingData.facebook" 
      :instagram="landingData.instagram"  
      :youtubeurl="landingData.youtube"
      :program="programs"
    />
    <!-- End Main Header -->
    <!--Main Slider-->
    <Slider :carousels="carousel" /> 
    <!--End Main Slider-->

    <!-- About Section Two -->
    <about-section idSection="about" :abouts="about"/>
    <!--End About Section Two -->

    <!-- Program Section -->
    <section class="program-section" id="program">
      <div class="auto-container">
        <div class="sec-title text-center">
          <h2>Program Kami</h2>
        </div>
        <program-section :programsData="programs" />
      </div>
    </section>
    <!--End Program Section -->

    <!-- Event Section -->
    <event-section idSection="event" :eventsData="events"/>
    <!--End Event Section -->

    <!-- Call To Action -->
    <call-to-action-section  idSection="callToAction" :tlp="landingData.tlp" />
    <!--End Call To Action -->

    <!-- Fluid Section One -->
    <contact-section idSection="contact" :tlp="landingData.tlp"  :latitude="landingData.lat" :langitude="landingData.lang"/>
    <!-- End Fluid Section One -->

    <!-- News Section -->
    <!-- <news-section idSection="news" /> -->
    <!--End News Section -->

    <!--Main Footer-->
    <footer-section idSection="footer" 
    :tlp="landingData.tlp" :email="landingData.email" :address="landingData.address"
    :facebookurl="landingData.facebook" :instagramurl="landingData.instagram" :youtubeurl="landingData.youtube" />
    <!--End Main Footer-->
  </div>
</template>

<script>
// component
import AboutSection from '../components/landing/AboutSection.vue';
import CallToActionSection from '../components/landing/CallToActionSection.vue';
import ContactSection from '../components/landing/ContactSection.vue';
import EventSection from '../components/landing/EventSection.vue';
import FooterSection from '../components/landing/FooterSection.vue';
import Header from '../components/landing/Header'
import ProgramSection from '../components/landing/ProgramSection.vue';
import Slider from '../components/landing/Slider'

// services
import LandingServices from '../services/landingServices';

export default {
  components: {
    Header, Slider,
    AboutSection,
    ProgramSection,
    EventSection,
    FooterSection,
    CallToActionSection,
    ContactSection,
  },
  data() {
    return {
      carousel: [],
      about:{},
      programs:[],
      events:[],
      landingData: {
        address: '',
        email: '',
        tlp: '',
        facebook: '',
        instagram: '',
        youtube: '',
        lat: '',
        lang: '',
      }
    }
  },
  mounted() {
      this.getLanding();
      this.webData();
},
  methods: {
    async getLanding() {
      try {
          const response = await LandingServices.getLanding()
          this.carousel = response.data.carousel;
          this.about = response.data.about;
          this.programs = response.data.program.data;
          this.events = response.data.event.data;
          console.log(response.data.program.data)
      } catch (error) {
        console.log(error);
      }
    },
    async webData() {
      try {
        const response = await LandingServices.dataWeb();
        // console.log(response.data)
        this.landingData = {
          address: response.data[8].value,
          email: response.data[5].value,
          tlp: response.data[15].value,
          facebook: response.data[17].value,
          instagram: response.data[19].value,
          youtube: response.data[18].value,
          lat: response.data[9].value,
          lang: response.data[10].value
        }
      } catch (error) {
        console.log(error);
      }
    }
  }
};
</script>

<style>
</style>