<template>
     <section class="call-to-action" :id="idSection">
      <div class="parallax-scene parallax-scene-3 anim-icons">
        <span
          data-depth="0.50"
          class="parallax-layer icon icon-balloon-3"
        ></span>
        <span data-depth="0.30" class="parallax-layer icon icon-star-6"></span>
        <span data-depth="0.40" class="parallax-layer icon icon-ball"></span>
        <span data-depth="0.20" class="parallax-layer icon icon-idea-2"></span>
        <span
          data-depth="0.50"
          class="parallax-layer icon icon-balloon-4"
        ></span>
      </div>

      <div class="auto-container">
        <div class="content-box">
          <span class="title">Hubungi Kami</span>
          <h2><span>{{tlp}}</span></h2>
        </div>
      </div>
    </section>
</template>

<script>
// services
export default {
  props:{ 
    idSection: String,
    tlp: String
  }
}
</script>

<style>

</style>