<template>
  <section class="fluid-section-two" :id="idSection">
      <div class="outer-container clearfix">
        <!--Content Column-->
        <div class="contact-column">
          <div class="parallax-scene parallax-scene-4 anim-icons">
            <span
              data-depth="0.50"
              class="parallax-layer icon icon-cloud-3"
            ></span>
            <span
              data-depth="0.30"
              class="parallax-layer icon icon-star-7"
            ></span>
            <span
              data-depth="0.40"
              class="parallax-layer icon icon-balloon-2"
            ></span>
          </div>

          <div class="inner-column clearfix">
            <div class="contact-form">
              <div class="sec-title light">
                <span class="title">Get in Touch</span>
                <h2>Contact Us</h2>
              </div>
                <div class="form-group">
                  <input
                    type="text"
                    name="username"
                    placeholder="Full name"
                    v-model="body.name"
                    required

                  />
                </div>

                <div class="form-group">
                  <input
                    type="email"
                    name="email"
                    placeholder="Email address"
                    v-model="body.email"

                    required=""
                  />
                </div>

                <div class="form-group">
                  <input
                    type="text"
                    name="phone"
                    placeholder="Phone number"
                     v-model="body.subject"
                    required=""
                  />
                </div>

                <div class="form-group">
                  <textarea
                    name="message"
                    placeholder="Write message"
                     v-model="body.message"

                  ></textarea>
                </div>

                <div class="form-group">
                  <button
                    class="theme-btn btn-style-one"
                    type="btn"
                    @click="sendContact()"
                    :disabled="loading == true"
                  >
                    {{loading == true ? 'loading...': 'Send'}}
                  </button>
                </div>
            </div>
          </div>
        </div>

        <!--Map Column-->
        <div class="map-column">
          <!--Map Outer-->
          <div class="map-outer">
            <!--Map Canvas-->
            <!-- <div
              class="map-canvas"
              data-zoom="18"
              :data-lat="latitude"
              :data-lng="langitude"
              data-type="roadmap"
              data-hue="#1F3828"
              data-title="BIS BALIKPAPAN"
              data-icon-path="images/icons/map-marker.png"
            ></div> -->
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.8767681460054!2d116.86510257576387!3d-1.244777735583822!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2df14642afb35d69%3A0x72df4e8e4d3be545!2sBalikpapan%20Islamic%20School!5e0!3m2!1sen!2sid!4v1715861460057!5m2!1sen!2sid" class="map-canvas" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>
          <div class="caption-box" style="background-color:#1FA34C !important;">
            <h3>{{tlp}}</h3>
          </div>
        </div>
      </div>
    </section>
</template>

<script>
import LandingServices from '../../services/landingServices';

export default {
  props: {
    idSection: String,
    tlp: String,
    latitude:String,
    langitude:String,
  },
  data() {
    return {
      loading: false,
      body: {
        name : '',
        email : '',
        subject: '',
        message: '',
      }
    }
  },
  methods: {
    sendContact() {
      this.loading = true
      console.log(this.body)
      LandingServices.sendContact(this.body)
      .then(res => {
        console.log(res);
        this.loading = false
        alert('Data berhasil dikirim');
      })
      .catch(err => {
        console.log(err);
        alert("terjadi kesalahan dalam mengirim data");
      });
    }
  }
}
</script>

<style>

</style>